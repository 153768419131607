(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Dependencies:
     *
     *    - icon
     */
    Vue.component("header-user", {
        props: [
            "userId", // int
            "username", // string
            "photo", // string
            "hasPremium", // boolean
            "hasProducts", // boolean
            "hasBigAvatar", // boolean
            "notificationsInput", // list of jsons
            "i18n", // json
        ],
        data: function () {
            return {
                showMenu: false,
                windowScreenPx: 0, // responsive measures
                heightScreenPX: 0,
                headerHeightPx: 0,
                notificationListDTO: [],
                urls: _generateURLs(),
            };
        },
        template: `
<div class="header-user">
<!--  <span v-if="!hasPremium" class="header-user__getpremium icon icon-star"></span>-->
  <div class="header-user__button" ref="button">
    <div :href="userURL(userId)" class="header-user__avatar"
       @click="onAvatarClick">
      <img class="header-user__avatar-img" :style="avatarImgSizeCssStyle" :src="userPhoto(photo)" :alt="username"/>
      <span v-if="hasNotifications" class="header-user__avatar-notification-counter">
        {{ notificationListDTO.length }}
      </span>
    </div>
    <div v-if="showMenu" class="header-user__menu-modal" :style="modalCssStyle" ref="menu">
      <div class="header-user__menu-inner">
        <div class="header-user__menu-content">
          <div class="header-user__menu-header" ref="header">
            <div class="header-user__menu-header-user">
              <img class="header-user__avatar-img" :src="userPhoto(photo)" :alt="username"/>
              <p class="header-user__menu-item-profile-name">{{ username }}</p>
            </div>
            <div class="header-user__menu-close">
              <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg" class="header-user__menu-close-button" @click="closeMenu"></img>
            </div>
          </div>  <!-- header-user__menu-header -->
          <div class="header-user__menu-items" :style="heightItemsResponsiveCssStyle">
            <div class="header-user__menu-items-top">
              <div v-if="hasNotifications" class="header-user__notifications">
                <p class="header-user__notifications-title">
                  {{ i18n.txtNotificationTitle }} ({{ notificationListDTO.length }})
                </p>
                <div v-for="(notification, index) in notificationListDTO" class="header-notification">
                  <a :href="userURL(notification.authorId)" class="header-notification__avatar">
                    <img :src="userPhoto(notification.authorPhoto)" :alt="username"/>
                  </a>
                  <div class="header-notification__body">
                    <p v-html="notification.title"></p>
                    <div class="header-notification__actions">
                      <button type="button" class="btn btn-primary btn-success header-notification__actions-confirm" @click="confirmNotification(notification, index)">
                        {{ i18n.txtNotificationConfirm }}
                      </button>
                      <button type="button" class="btn" @click="ignoreNotification(notification, index)">
                        {{ i18n.txtNotificationIgnore }}
                      </button>
                    </div>
                  </div>
                </div>
              </div> <!-- notifications -->
              <a onclick="gtagEvent('goto_user_profile_click', { ref: 'header'});" class="header-user__menu-item" :href="userURL(userId)">
                <span class="icon icon-user"></span>
                <div class="header-user__menu-item-profile">
                  <p class="header-user__menu-item-profile-name">{{ username }}</p>
                  <p>{{ i18n.txtProfile }}</p>
                </div>
              </a>
              <a class="header-user__menu-item" onclick="gtagEvent('goto_settings_click', { ref: 'header'});" :href="urls.settings">
                <span class="icon icon-settings-alt"></span>
                <p>{{ i18n.txtSettings }}</p>
              </a>
              <a v-if="hasProducts" onclick="gtagEvent('goto_products_click', { ref: 'header'});" class="header-user__menu-item" :href="urls.products">
                <span class="icon icon-box"></span>
                <p>{{ i18n.txtProducts }}</p>
              </a>
              <a v-if="!hasPremium" class="header-user__menu-item header-user__menu-item--accent" :href="urls.premium">
              
                <span class="header-user__menu-item-premium icon icon-star"></span>
                <p>{{ i18n.txtGetPremium }}</p>
              </a>
              <a onclick="gtagEvent('goto_your_trails_click', { ref: 'header'});" class="header-user__menu-item" :href="urls.yourTrails" >
                <span class="icon icon-address"></span>
                <p>{{ i18n.txtTrails }}</p>
              </a>
            </div>
            <div class="header-user__menu-items-bottom">
            <a class="header-user__menu-item" :href="urls.signout">
              <span class="icon icon-logout"></span>
              <p>{{ i18n.txtSignOut }}</p>
            </a>
            </div>
          </div>  <!-- header-user__menu-items -->
        </div> <!-- header-user__menu-content -->
      </div> <!-- header-user__menu-inner -->
    </div> <!-- header-user__menu-modal -->
  </div>
</div>
`,
        methods: {
            onAvatarClick: onAvatarClick,
            // onAvatarEnter: onAvatarEnter(),
            // onAvatarLeave: onAvatarLeave(),
            onClickOutsideMenuDesktop: onClickOutsideMenuDesktop,
            openMenu: openMenu,
            closeMenu: closeMenu,
            userPhoto: userPhoto,
            userURL: userURL,
            confirmNotification: confirmNotification,
            ignoreNotification: ignoreNotification,
            readScreenDimensions: readScreenDimensions,
            readHeaderDimensions: readHeaderDimensions,
        },
        computed: {
            isResponsive: isResponsive,
            hasNotifications: hasNotifications,
            heightItemsResponsiveCssStyle: heightItemsResponsiveCssStyle,
            modalCssStyle: modalCssStyle,
            avatarImgSizeCssStyle: avatarImgSizeCssStyle,
        },
        watch: {},
        //VUE LIFECYCLE
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */
    const RESPONSIVE_WIDTH_THRESHOLD_PX = 768;

    const WIDTH_NO_NOTIFICATIONS_PX = 220;
    const WIDTH_NOTIFICATIONS_PX = 380;

    const AVATAR_IMG_SIZE_NORMAL_PX = 40;
    const AVATAR_IMG_SIZE_BIG_PX = 54;

    const HEADER_USER_MENU_MODAL_TOP_DELTA_PX = 8;

    const REMOVE_NOTIFICATION_TTL_MS = 250; //0.25s
    const DEFAULT_PHOTO =
        "https://sc.wklcdn.com/wikiloc/images/noface50x50.png";

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function onAvatarClick() {
        if (!this.showMenu) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    function onClickOutsideMenuDesktop(event) {
        if (!this.isResponsive) {
            var component = this.$refs.menu;
            var componentButton = this.$refs.button;
            //The click is in the component
            if (
                !component ||
                !componentButton ||
                component.contains(event.target) ||
                componentButton.contains(event.target)
            ) {
                return;
            } else {
                this.closeMenu();
            }
        }
    }

    function openMenu() {
        this.showMenu = true;

        if (this.isResponsive) {
            this.readHeaderDimensions();
            _doubleRaf(() => {
                _enableDocumentScroll(false);
            });
        }
    }

    function closeMenu() {
        if (this.isResponsive) {
            _doubleRaf(() => {
                _enableDocumentScroll(true);
            });
        }

        this.showMenu = false;
    }

    function userPhoto(photo) {
        return photo ? photo : DEFAULT_PHOTO;
    }

    function userURL(userId) {
        return window.location.origin + "/wikiloc/user.do?id=" + userId;
    }

    function confirmNotification(notification, index) {
        _makeNotificationAjax(this, notification.urlConfirm, index);
    }

    function ignoreNotification(notification, index) {
        _makeNotificationAjax(this, notification.urlCancel, index);
    }

    /**
     * We get the screen dimensions to use them as part of computed properties
     */
    function readScreenDimensions() {
        this.heightScreenPX = window.innerHeight;
        this.widthScreenPx = window.innerWidth;
    }

    function readHeaderDimensions() {
        this.$nextTick(() => {
            this.headerHeightPx = this.$refs.header.clientHeight;
        });
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */
    function isResponsive() {
        return this.widthScreenPx <= RESPONSIVE_WIDTH_THRESHOLD_PX;
    }

    function hasNotifications() {
        return this.notificationListDTO.length > 0;
    }

    /**
     * Height in Responsive mode should be static to allow fullscreen mode
     * that works in mobile devices due to BUG in 100% or 100vh
     * NOTE: we can't do this with only CSS
     */
    function heightItemsResponsiveCssStyle() {
        var styleCss = {};

        if (this.isResponsive) {
            let offset = this.headerHeightPx;
            offset = offset + this.footerHeightPx;

            let height = this.heightScreenPX - offset;
            styleCss = {
                height: height + "px",
            };
        }

        return styleCss;
    }

    function modalCssStyle() {
        let width = this.hasNotifications
            ? WIDTH_NOTIFICATIONS_PX
            : WIDTH_NO_NOTIFICATIONS_PX;
        let avatarImgSize = this.hasBigAvatar
            ? AVATAR_IMG_SIZE_BIG_PX
            : AVATAR_IMG_SIZE_NORMAL_PX;

        return {
            width: width + "px",
            top: avatarImgSize + HEADER_USER_MENU_MODAL_TOP_DELTA_PX + "px",
        };
    }

    function avatarImgSizeCssStyle() {
        let size = this.hasBigAvatar
            ? AVATAR_IMG_SIZE_BIG_PX
            : AVATAR_IMG_SIZE_NORMAL_PX;

        return {
            width: size + "px",
            height: size + "px",
        };
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */
    function mounted() {
        //1 - Native event Listeners
        document.addEventListener("mousedown", this.onClickOutsideMenuDesktop);

        //2 - Vue eventbus Listeners

        //3 - Init
        this.readScreenDimensions();

        // notification input
        _doubleRaf(() => {
            if (this.notificationsInput && this.notificationsInput.length > 0) {
                this.notificationListDTO = _factoryNotifications(
                    this.notificationsInput
                );
            }
        });

        //4 - After the DOM is mounted
        this.$nextTick(() => {
            //4.1 - Detect screen resize
            window.addEventListener("resize", (event) => {
                this.readScreenDimensions();
            });
        });
    }

    function beforeDestroy() {
        //1 - Native event Listeners
        document.removeEventListener(
            "mousedown",
            this.onClickOutsideMenuDesktop
        );
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * EXPERT TIP.
     * Wait the double of frames than vue.nextTick()
     * Sometimes the computation is much bigger than what nextTick can handle.
     * We need to wait the double of frames: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
     * @param callback
     */
    function _doubleRaf(callback) {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let value = enable ? "auto" : "hidden";

        document.body.style.overflow = value;
    }

    function _generateURLs() {
        return {
            yourTrails: window.location.origin + "/wikiloc/spatialArtifacts.do",
            settings: window.location.origin + "/wikiloc/showProfile.do",
            products: window.location.origin + "/wikiloc/showProducts.do",
            premium: window.location.origin + "/premium?ref=menu",
            signout: window.location.origin + "/wikiloc/logout.do",
        };
    }

    function _makeNotificationAjax(vueInstance, url, index) {
        _ajaxPost(url, {}, () => {
            setTimeout(() => {
                _removeNotification(vueInstance, index);
            }, REMOVE_NOTIFICATION_TTL_MS);
        });
    }

    /**
     *    Post Async ajax call
     */
    function _ajaxPost(url, formData, callback) {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                callback();
            }
        };

        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8"
        );
        xmlhttp.send(formData);
    }

    function _removeNotification(vueInstance, index) {
        vueInstance.notificationListDTO.splice(index, 1);
    }

    function _factoryNotifications(notificationsInput) {
        let notifications = [];

        for (var i = 0; i < notificationsInput.length; i++) {
            let notification = _factoryNotification(notificationsInput[i]);
            notifications.push(notification);
        }

        return notifications;
    }

    function _factoryNotification(notificationInput) {
        return new NotificationDTO(
            notificationInput.id,
            notificationInput.type,
            notificationInput.title,

            notificationInput.authorId,
            notificationInput.authorName,
            notificationInput.authorPhoto,

            notificationInput.urlConfirm,
            notificationInput.urlCancel
        );
    }

    /**
     * --------------
     * ---- DTOs ----
     * --------------
     *
     */
    function NotificationDTO(
        id,
        type,
        title,
        authorId,
        authorName,
        authorPhoto,
        urlConfirm,
        urlCancel
    ) {
        this.id = id;
        this.type = type;
        this.title = title;

        this.authorId = authorId;
        this.authorName = authorName;
        this.authorPhoto = authorPhoto;

        this.urlConfirm = urlConfirm;
        this.urlCancel = urlCancel;
    }

    ///////////////////////////////////////////////////////////////////////
    //A Vue Main (check for multiple instances)
    var elementId = "vue-header-user";
    if (!document.getElementById(elementId)) {
        return null;
    }

    new Vue({
        el: "#" + elementId,
        data: {
            userId: INJECT_HEADER.userId,
            username: INJECT_HEADER.username,
            photo: INJECT_HEADER.photo,
            hasPremium: INJECT_HEADER.hasPremium,
            hasProducts: INJECT_HEADER.hasProducts,
            hasBigAvatar: INJECT_HEADER.hasBigAvatar,
            notificationsInput: INJECT_HEADER.notifications,
            i18n: INJECT_HEADER.i18n,
        },
        template: `
			<header-user
			  :userId="userId"
			  :username="username"
			  :photo="photo"
			  :hasPremium="hasPremium"
			  :hasProducts="hasProducts"
			  :hasBigAvatar="hasBigAvatar"
			  :notificationsInput="notificationsInput"
			  :i18n="i18n"
			>
			</header-user>
		`,
        methods: {},
        mounted: function () {},
        beforeDestroy: function () {},
    });
})();
